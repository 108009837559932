<template>
  <div>
    <el-input
      type="textarea"
      :rows="2"
      placeholder="请输入内容"
      v-model="textarea"
      @input="handleTextareaInput(textarea)"
    >
    </el-input>
    <el-button type="primary" @click="handleClick">按钮</el-button>
    <template v-for="inp in inputs" :key="inp.id">
      <el-input
        v-model="inp.value"
        @input="handleInputEdit(inp.id, textarea)"
      />
    </template>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
const textarea = ref("");
const inputs = reactive([]);
const underlinesArray = ref([]);
const handleClick = () => {
  // 文本中插入____
  textarea.value += "____";
  // 获取文本中所有下划线的位置
  const underlines = findAllUnderlines(textarea);
  // 将所有下划线的位置存入underlinesArray
  underlinesArray.value = underlines;
  inputs.push({
    id: new Date().getTime(),
    value: "",
  });
};

const handleTextareaInput = () => {
  // 使用正则表达式查找所有下划线的位置
  const regex = /_/g;
  const matches = textarea.value.match(regex);

  if (matches && matches.length > 0) {
    // 将不是连续四个下划线的其他下划线替换为空
    textarea.value = textarea.value.replace(/(?<!_)(?<!__)(?<!___)_/g, "");
  }

  // 获取文本中所有下划线的位置
  const underlines = findAllUnderlines(textarea.value);
  console.log(underlines);
  // 对比underlinesArray和underlines如果underlinesArray长度大于underlines则删除inputs中对应缺少的input
  if (underlinesArray.value.length > underlines.length) {
    // 查找不一样的下划线索引
    const diffIndex = underlinesArray.value.findIndex(
      (item, index) => item !== underlines[index]
    );
    console.log("diffIndex", diffIndex);
    // 删除inputs中对应的input
    inputs.splice(diffIndex, 1);
  }
  underlinesArray.value = underlines;
};

const findAllUnderlines = (text) => {
  // 使用正则表达式查找所有下划线的位置
  const regex = /____/g;
  const matches = [];
  let match;
  while ((match = regex.exec(text)) !== null) {
    matches.push(match.index); // 不进行除法操作，直接返回下划线在 text 中的索引
  }
  return matches;
};
</script>
